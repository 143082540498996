<template>
    <div class="event">
      <router-view />
    </div>
</template>

<script>
    // @ is an alias to /src

    export default {
        name: 'event',
        data() {
            return {
                eventname: 'test',
                oldTab: 0,
                transition: 'slide-left'
            }
        }
    }
</script>

<style>


</style>
